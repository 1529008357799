<template>
  <v-container class="box__container" v-if="!loading">
    <template v-if="showSelectMethodPay">
      <h3>{{ $t("selectMethodPayment") }}</h3>
      <section>
        <v-radio-group
          row
          v-model="methodPaySelected"
          @change="handleSelectMethodPay"
        >
          <v-radio :label="$t('paymentCash')" value="cash" />
          <v-radio :label="$t('paymentCard')" value="card" />
        </v-radio-group>
      </section>
    </template>
    <template v-else>
      <h3 v-if="methodPay">
        {{ $t("methodPaymentSelected") }}

        {{ $t(methodPaymentKeyTranslation) }}
      </h3>
      <h3 v-else class="red--text">
        {{ $t("notMethodPayment") }}
      </h3>
    </template>
  </v-container>
</template>
<script>
import { settingCtrl } from "@/controllers";
import { orderTypeStoreGetters } from "@/store/modules/orderType/constNames.js";
import { TYPE_PAYMENT } from "./enums";

export default {
  name: "SelectMethodPay",
  data() {
    return {
      loading: true,
      methodPay: null,
      methodPaySelected: null,
    };
  },
  async mounted() {
    await this.getMethodPay();
  },
  methods: {
    async getMethodPay() {
      try {
        const methodPay = await settingCtrl.getMethodPay(
          this.IHaveSelectedAnOrderType
        );

        this.methodPay = methodPay;

        !this.showSelectMethodPay &&
          this.emitSetMethodPaySelected(this.methodPay);
      } catch (error) {
        const errorPayload = getErrorPayloadForSnackbar(error);
        this.$store.commit("user/OPEN_SNACKBAR", errorPayload);
      }
      this.loading = false;
    },
    handleSelectMethodPay() {
      this.emitSetMethodPaySelected(this.methodPaySelected);
    },
    emitSetMethodPaySelected(methodPay) {
      this.$emit("setMethodPaySelected", methodPay);
    },
  },
  computed: {
    IHaveSelectedAnOrderType() {
      return this.$store.getters[orderTypeStoreGetters.GET_ORDER_TYPE];
    },
    showSelectMethodPay() {
      return this.methodPay === TYPE_PAYMENT.BOTH;
    },
    methodPaymentKeyTranslation() {
      if (!this.methodPay) return "";

      const firstLetterCapitalize = this.methodPay.charAt(0).toUpperCase();
      const restString = this.methodPay.substring(1);

      return `payment${firstLetterCapitalize + restString}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.box__container {
  background: #fff;
  border-radius: 20px;
  padding: 10px 20px;
}
</style>
